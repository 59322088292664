<template>
  <div>
    <h1>Kurse</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col v-if="user.role !=='user'" class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createCourse"
            >
              <v-icon left>mdi-plus</v-icon>
              Kurse
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="courses"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="courses.length < 5"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <template v-slot:item.author="{ item }">
          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{item.author.firstName | uppercaseFirstLetter }}{{item.author.lastName | uppercaseFirstLetter }}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.status="{ item }" class="ml-0">
          <v-chip
              :color="item.status === 'draft' ?  'secondary' : 'success'"
              class="ma-0"
              small
              style="width: 80px"
          >
            {{item.status}}
          </v-chip>
        </template>
        <template v-slot:item.createdAt="{item}" class="ml-0">
          {{item.createdAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              v-if="user.role !== 'user'"
              small
              class="mr-2"
              @click="copyItem(item)"
          >
            mdi-content-copy
          </v-icon>
          <v-icon
              v-if="user.role !== 'user'"
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <course-edit-dialog ref="courseEditDialog" @updated="fetchCourses"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import CourseEditDialog from '@/components/CourseEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapState} from "vuex";

export default {
  name: 'CourseList',
  mixins: [vuetifyTableMixin],
  components: { CourseEditDialog, ConfirmDialog },
  data () {
    return {
      courses: [],
      headers: [
        {
          text: 'Titel',
          align: 'start',
          sortable: true,
          value: 'title',
          width: 400
        },
        {
          text: 'Autor',
          value: 'author',
          sortable: true
        },
        {
          text: 'Erstellt am',
          value: 'createdAt',
          sortable: true
        },
        {
          text: 'Zuletzt bearbeitet am',
          value: 'updatedAt',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true
        },
        {
          text: 'Aktionen',
          value: 'actions',
          align: 'end',
          width: 100,
        }
      ],
      search: ''
    }
  },
  methods: {
    createCourse () {
      this.$refs.courseEditDialog.create()
    },
    copyItem(course) {
      APIService.getCourse(course).then(course => {
        console.log('Course', course)
        course.title = `${course.title} Kopie`
        delete course.id
        delete course.createdAt
        delete course.updatedAt
        delete course.author
        delete course.lastUpdatedBy
        course.status = 'draft'
        course.chapters = course.chapters.map(chapter => ({...chapter, startDate: undefined}))
        this.$refs.courseEditDialog.edit(course)
      })
    },
    editItem (course) {
      APIService.getCourse(course).then(course => {
        console.log('Course', course)
        this.$refs.courseEditDialog.edit(course)
      })
    },
    async deleteItem (course) {
      await this.$refs.confirmDelete.show({
        title: 'Kurs entfernen',
        text: `Wollen sie <b>${course.title}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteCourse(course)
      this.courses = await APIService.getCourses()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchCourses() {
      this.courses = await APIService.getCourses()
    }
  },
  computed: {
    ...mapState(['user'])
  },
  async beforeRouteEnter(to, from, next) {
    const courses = await APIService.getCourses()
    next(vm => vm.courses = courses)
  },
  async beforeRouteUpdate(to,from,next) {
    this.courses = await APIService.getCourses()
    next()
  }
}
</script>

<style scoped>

</style>
