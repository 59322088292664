<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
       Datei hochladen
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <file-upload :description="description" :unzipContent="false" @uploaded="fileUploaded" @started="uploadStarted"/>
            <div v-if="fileMeta">Dateiname: {{fileMeta.originalName}}</div>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Beschreibung" v-model="description"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="close"
        >
         Abbrechen
        </v-btn>
        <v-btn
          :disabled="!isValid"
          color="primary"
          text
          @click="doConfirm"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FileUpload from "@/components/FileUpload";

export default {
  name: 'CourseUploadDialog',
  components: {FileUpload},
  data () {
    return {
      showDialog: false,
      resolve: null,
      description: '',
      isValid: false,
      fileMeta: undefined,
    }
  },
  methods: {
    show () {
      this.description = ''
      this.isValid = false
      this.showDialog = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    doConfirm () {
      this.close()
      console.log(this.fileMeta)
      const file = {...this.fileMeta}
      this.fileMeta = undefined
      this.resolve({file,  description: this.description})
    },
    close() {
      this.showDialog = false
    },
    fileUploaded(fileMeta) {
      console.log("File uploaded", fileMeta)
      this.isValid = true
      this.fileMeta = fileMeta
    },
    uploadStarted() {
      this.isValid = false
    },
  },
}
</script>

<style scoped>

</style>
