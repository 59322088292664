<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
       Kurs-Termin
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
          <v-text-field
              v-model="event.title"
              label="Titel"
              required
          ></v-text-field>
        </v-col>
          <v-col cols="12">
            <div class="mb-2">Beschreibung</div>
            <tiptap-vuetify
                v-model="event.description"
                :extensions="extensions"
            />
          </v-col>
        <v-col cols="12">
          <date-time-picker label="Beginn" v-model="event.startDate"/>
        </v-col>
        <v-col cols="12">
          <date-time-picker label="Ende" v-model="event.endDate"/>{{isInputValid}}
        </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click="close"
        >
         Abbrechen
        </v-btn>
        <v-btn
          :disabled="!isInputValid"
          color="primary"
          text
          @click="doConfirm"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateTimePicker from "@/components/DateTimePicker";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  name: 'CourseEventDialog',
  components: {DateTimePicker, TiptapVuetify},
  data () {
    return {
      showDialog: false,
      resolve: null,
      event: {},
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }
  },
  methods: {
    show (event) {
      this.event =  event ? { ...event } : {}
      this.showDialog = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    doConfirm () {
      this.close()
      console.log(this.event)
      this.resolve(this.event)
    },
    close() {
      this.showDialog = false
    }
  },
  computed: {
    isInputValid: function() {
      return this.event.title && !!this.event.startDate && !!this.event.endDate
    }
  }
}
</script>

<style scoped>

</style>
