<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Kurs</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="courseForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div v-if="course.id" class="course-info">
                    <div v-if="course.createdAt">
                      Erstellt am:
                      {{course.createdAt | formatLocaleDate}}
                    </div>
                    <div v-if="course.author">
                      Erstellt von:
                      {{course.author.firstName}} {{course.author.lastName}}
                    </div>
                    <div v-if="course.lastUpdatedBy">
                      Zuletzt bearbeitet von:
                      {{course.lastUpdatedBy.firstName}} {{course.lastUpdatedBy.lastName}}
                    </div>
                    <div v-if="course.updatedAt">
                      Zuletzt bearbeitet am:
                      {{course.updatedAt | formatLocaleDate}}
                    </div>
                    <div v-if="course.id">
                      Chat-ID: {{course.id}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="course.title"
                      label="Titel"
                      required
                      :rules="courseTitleRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="course.tags"
                      :disabled="isUpdating"
                      :items="allTags"
                      filled
                      chips
                      label="Tags"
                      item-text="name"
                      item-value="id"
                      item-color="dark-grey"
                      multiple
                  >
                    <!--                      append-icon="mdi-plus"-->
                    <!--                      @click:append="addLabel"-->

                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          :color="data.item.color"
                          text-color="white"
                          @click="data.select"
                          @click:close="removeTag(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content class="ma-0">
                          <v-list-item-title>
                            <v-chip
                                v-html="data.item.name"
                                class="ma-0"
                                close
                                :color="data.item.color"
                                text-color="white"
                            />
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <tiptap-vuetify
                      v-model="course.description"
                      :extensions="extensions"
                  />
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="course.image"
                      label="Titelbild"
                  />
                </v-col>
                <v-col cols="12">
                  <date-picker label="Kursbeginn" v-model="course.startDate"/>
                </v-col>
                <v-col cols="12">

                  <v-expansion-panels
                      v-model="chapterPanel"
                      multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Kapitel</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <draggable
                            :list="selectedChapters"
                            class="list-group"
                            ghost-class="ghost"
                            :move="checkMove"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                          <v-card
                              class="list-group-item"
                              v-for="element in selectedChapters"
                              :key="element.id"
                          >
                            <v-card-text>
                              <v-expansion-panels
                                  multiple
                                  flat
                              >
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <v-row
                                    >
                                      {{ element.title }}
                                      <v-spacer/>
                                      <v-icon
                                          class="mr-5"
                                          color="secondary"
                                          small
                                          @click.stop="deleteItem(element)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-row>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                      <v-col cols="12">
                                        <span class="pb-4" v-html="element.description"/>
                                      </v-col>
                                      <v-col cols="12">
                                        <date-picker v-if="!course.startDate" label="Startdatum" v-model="element.startDate"/>
                                        <template v-else>
                                          <v-text-field v-model.number="element.startOffsetDays" label="Verfügbarkeit in Tagen nach Kursbeginn"/>
                                          Effektives Datum: {{element.startOffsetDays | effectiveDate(course.startDate)}}
                                        </template>
                                      </v-col>
                                      <v-col cols="12">
                                        <v-text-field label="Alias-Titel für Kurs" v-model="element.aliasTitle"/>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-text>
                          </v-card>
                        </draggable>
                        <v-row class="mt-2">
                          <v-col>
                            <v-autocomplete
                                v-model="selectedChapter"
                                :items="chapters"
                                cache-items
                                dense
                                class="mx-4"
                                hide-no-data
                                hide-details
                                label="Kapitel"
                                item-text="title"
                                item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-btn
                                rounded
                                dense
                                color="primary"
                                :disabled="selectedChapter === null"
                                @click="addChapter"
                            >
                              Hinzufügen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="course.edition"
                      label="Auflage"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="course.status" :items="states" label="Status"></v-select>
                </v-col>
                <v-col v-if="user.role !== 'user'" cols="12">
                  <user-select
                      :value.sync="course.managers"
                      :users="users"
                      label="Kursmanager"
                      multiple
                      @removeItem="removeManager"
                  />
                </v-col>
                <v-col cols="12">

                  <v-expansion-panels
                      v-model="extensionPanel"
                      v-if="course.extensions"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Erweiterungen</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Dateien" v-model="course.extensions.uploads"></v-checkbox>

                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Termine" v-model="course.extensions.events"></v-checkbox>

                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Chat" v-model="course.extensions.chat"></v-checkbox>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Zertifikate" v-model="course.extensions.certificates"></v-checkbox>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Badges" v-model="course.extensions.badges"></v-checkbox>
                          </v-col>
                          <v-col cols="6" class="my-0 py-0">
                            <v-checkbox label="Kommilitonen anzeigen" v-model="course.extensions.showFellowStudents"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">

                  <v-expansion-panels
                      v-if="course.extensions && course.extensions.uploads"
                      v-model="uploadPanel"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Dateien</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="text-right mb-6">
                            <v-btn
                                rounded
                                color="primary"
                                dark
                                @click="addUpload"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Datei
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                            :headers="uploadHeaders"
                            :items="course.uploads"
                            :items-per-page="10"
                            class="elevation-1"
                            sort-by="originalName"
                            :sort-desc="true"
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                @click="deleteUpload(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">

                  <v-expansion-panels
                      v-if="course.extensions && course.extensions.events"
                      v-model="eventPanel"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Termine</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="text-right mb-6">
                            <v-btn
                                rounded
                                color="primary"
                                dark
                                @click="addEvent"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Termin
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                            :headers="headers"
                            :items="course.events"
                            :items-per-page="10"
                            class="elevation-1"
                            sort-by="startDate"
                            :sort-desc="true"
                        >
                          <template v-slot:item.startDate="{ item }">
                            {{item.startDate | formatLocaleDateTime}}
                          </template>
                          <template v-slot:item.endDate="{ item }">
                            {{item.endDate | formatLocaleDateTime}}
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editEvent(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteEvent(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      v-model="course.comment"
                      label="Kommentar"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCourse"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <course-event-dialog ref="courseEventDialog"/>
    <course-upload-dialog ref="courseUploadDialog"/>
    <snackbar ref="snackbar"></snackbar>
    <v-snackbar
        v-model="saveSuccess"
        color="success"
        multi-line="multi-line"
        timeout="2000"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>Kurs wurde erfolgreich gespeichert</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import CourseEventDialog from '@/components/CourseEventDialog'
import Snackbar from "@/components/Snackbar";
import draggable from 'vuedraggable'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import DatePicker from "@/components/DatePicker";
import ImageInput from "@/components/ImageInput";
import CourseUploadDialog from "@/components/CourseUploadDialog";
import UserSelect from "@/components/UserSelect";


const COURSE_INIT_DATA = {
  status: 'draft',
  chapters: [],
  extensions: {},
  events: [],
  uploads: [],
}
export default {
  name: 'CourseEditDialog',
  components: {
    UserSelect, ImageInput, DatePicker, CourseEventDialog, CourseUploadDialog, Snackbar, ConfirmDialog, draggable, TiptapVuetify },
  data() {
    return {
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,

      date: undefined,
      menu1: false,
      showDialog: false,
      values: [],
      value: null,
      course: [],
      saveSuccess: false,
      isCourseTitleTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: 'Kurs erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      chapters: [],
      filteredChapters: [],
      states: [
        { text: 'Entwurf', value: 'draft'},
        { text: 'Veröffentlicht', value: 'published' },
      ],
      selectedChapter: null,
      selectedChapters: [],
      dragging: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      chapterPanel: false,
      extensionPanel: false,
      uploadPanel: false,
      eventPanel: false,
      loading: false,

      autoUpdate: true,
      isUpdating: false,
      name: 'Midnight Crew',
      allTags: [],
      headers: [
        {
          text: 'Titel',
          align: 'left',
          sortable: true,
          value: 'title',
        },
        { text: 'Beginn',
          value: 'startDate',
          width: 180,
          sortable: true,
        },
        { text: 'Ende',
          value: 'endDate',
          width: 180,
          sortable: true,
        },
        {
          text: 'Aktionen',
          value: 'actions',
          align: 'end'
        }
      ],
      uploadHeaders: [
        {
          text: 'Dateiname',
          align: 'left',
          sortable: true,
          value: 'file.originalName',
        },
        { text: 'Beschreibung',
          value: 'description',
          sortable: true,
        },
        {
          text: 'Aktionen',
          value: 'actions',
          align: 'end'
        }
      ],
    }
  },
  methods: {
    async addEvent() {
      this.$refs.courseEventDialog.show().then((event) => {
        const id = this.course.events.length ? Math.max(...this.course.events.map(event => event.id)) + 1 : 1
        this.course.events.push({...event, id})
      })
    },
    async addUpload() {
      this.$refs.courseUploadDialog.show().then((upload) => {
        console.log("File uploaded", upload)
        const id = this.course.uploads.length ? Math.max(...this.course.uploads.map(upload => upload.id)) + 1 : 1
        this.course.uploads.push({...upload, id})
      })
    },
    async editEvent(event) {
      this.$refs.courseEventDialog.show(event).then((event) => {
        console.log("Event emitted", event)
        this.course.events = this.course.events.map(e => event.id === e.id ? event : e)
      })
    },
    async deleteEvent(event) {
      await this.$refs.confirmDialog.show({
        title: 'Termin entfernen',
        text: 'Wollen sie den gewählten Termin entfernen?',
        confirm: 'entfernen'
      })
      this.course.events = this.course.events.filter(e => event.id !== e.id)
    },
    async deleteUpload(upload) {
      console.log("upload", upload)
      await this.$refs.confirmDialog.show({
        title: 'Datei entfernen',
        text: `Wollen sie die Datei <b>${ upload.file ? upload.file.originalName : '' }</b> entfernen?`,
        confirm: 'entfernen'
      })
      this.course.uploads = this.course.uploads.filter((u) => upload.id !== u.id)
    },
    async create () {
      this.course = { ...COURSE_INIT_DATA }
      this.chapters = await APIService.getChapters()
      this.allTags = await APIService.getTags()
      this.showDialog = true
    },
    async edit(course) {
      this.course = course
      this.course.events = this.course.events.map((event,index) => ({...event, id: index+1}))
      this.course.uploads = this.course.uploads.map((event,index) => ({...event, id: index+1}))
      this.chapters = await APIService.getChapters()
      this.allTags = await APIService.getTags()
      this.selectedChapters = this.course?.chapters?.map(chapter => ({
        ...this.chapters.find(c => chapter.chapter === c.id),
        ...chapter,
        startOffsetDays: chapter.startOffsetDays ?? 0,
      }))
      this.showDialog = true
    },
    close() {
      this.course = { ...COURSE_INIT_DATA }
      this.selectedChapter = null
      this.selectedChapters = []
      this.chapterPanel = undefined
      this.$refs.courseForm.resetValidation()
      this.chapters = []
      this.allTags = []
      this.eventPanel = false
      this.uploadPanel = false
      this.extensionPanel = false
      this.showDialog = false
    },
    async saveCourse() {
      if (!this.$refs.courseForm.validate()) {
        return
      }
      this.course.chapters = this.selectedChapters.map((chapter) => ({
        chapter: chapter.id,
        startOffsetDays: chapter.startOffsetDays,
        ...chapter.startDate ? {startDate: chapter.startDate} : {},
        ...chapter.aliasTitle ?  {aliasTitle: chapter.aliasTitle} : {},
      }))
      this.course.uploads = this.course.uploads.map((upload) => ({
        ...upload.file.id ? {file: upload.file.id} : {},
        path: upload.file.path,
        description: upload.description,
        uploadedBy: this.user.id,
      }))
      await APIService.saveCourse(this.course)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      this.close()
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    courseTitleRules() {
      return [
        value => !!value || 'Kurstitel erforderlich.',
        !this.isCourseTitleTaken || 'Der Kurstitel ist bereits vergeben.'
      ]
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    deleteItem(chapter) {
      console.log("Delete item", chapter)
      this.selectedChapters = this.selectedChapters.filter((_chapter) => chapter.id !== _chapter.id)
    },
    addChapter() {
      if(this.selectedChapters.find((chapter) =>  chapter.id === this.selectedChapter)) {
        console.log("Already added")
        return
      }
      this.selectedChapters.push({...this.chapters.find((chapter) => chapter.id === this.selectedChapter)})
      this.selectedChapter = null
    },
    removeTag (item) {
      const index = this.course.tags.indexOf(item.id)
      if (index >= 0) this.course.tags.splice(index, 1)
    },
    removeManager(item) {
      const index = this.course.managers.indexOf(item.id)
      if (index >= 0) this.course.managers.splice(index, 1)
    },
  },
  computed: {
    ...mapState(['user', 'users']),
  },
  watch: {
    "course.name"() {
      this.isCourseTitleTaken = false
    },
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
